<div class="row first-row">
  <!-- <custom-menu position="footer" class="first-menu" [horizontal]="true"></custom-menu> -->
  <link href="https://res.cloudinary.com/dpyy9uysx/raw/upload/v1582801042/maxpay/bootstrap.min.css" rel="stylesheet">
    <footer class="footer">
      <div class="container">
        <div class="top-footer">
            <div class="row">
              <div class="col-3">
                  <a href="/contact" class="mat-button">
                    <span class="mat-button-wrapper">Contact</span></a>
              </div>
              <div class="col-3">
                  <a href="https://literalbuilder.com/legal/tos.html" class="mat-button">
                    <span class="mat-button-wrapper">Terms of Service</span></a>
                </div>
                <div class="col-3">
                    <a href="https://literalbuilder.com/legal/privacy.html" class="mat-button">
                      <span class="mat-button-wrapper">Privacy Policy</span></a>
                  </div>
            </div>  
            <custom-menu position="footer-secondary" class="second-menu" [horizontal]="true"></custom-menu>
        </div>
        <div class="second-row">
          <div class="copyright first-col">
            {{ copyrightText }}
          </div>
          <div class="selectors second-col">
            <app-footer-lang-switcher></app-footer-lang-switcher>
          </div>
        </div>
      </div>
    </footer>
</div>
